import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

const NavbarStyles = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-family: var(--header-font);

  background-color: var(--green);

  .desktop {
    display: grid;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 1100px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: grid;
    }
  }

  @media (pointer: none), (pointer: coarse) {
    .desktop {
      display: none;
    }

    .mobile {
      display: grid;
    }
  }
`;

export default function Navbar({ location }) {
  const data = useStaticQuery(graphql`
    query {
      pages: allSanityPage {
        nodes {
          slug {
            current
          }
          title
          sortingId
        }
      }

      categories: allSanityCategory {
        edges {
          node {
            slug {
              current
            }
            title
          }
        }
      }

      pillarPosts: allSanityBlog(
        filter: { publicityStatus: { eq: "PUBLISHED" }, pillar: { eq: true } }
      ) {
        edges {
          node {
            title
            slug {
              current
            }

            blogCategory {
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const menus = [
    // {
    //   title: "BLOG",
    //   children: [
    //     ...data.categories.edges.map((category) => ({
    //       title: category.node.title.toUpperCase(),
    //       children: [
    //         {
    //           title: (
    //             <strong>ALL {category.node.title.toUpperCase()} POSTS</strong>
    //           ),
    //           url: `/${category.node.slug.current}/`,
    //         },
    //         ...data.pillarPosts.edges
    //           .filter(
    //             (post) =>
    //               post.node.blogCategory.slug.current ===
    //               category.node.slug.current,
    //           )
    //           .map((pillarPost) => ({
    //             title: pillarPost.node.title.toUpperCase(),
    //             url: `/${category.node.slug.current}/${pillarPost.node.slug.current}/`,
    //           })),
    //       ],
    //     })),
    //   ],
    // },

    ...data.pages.nodes
      .map((page) => ({
        title: page.title.toUpperCase(),
        url: `/${page.slug.current}/`,
        sortingId: page.sortingId,
      }))
      .sort((a, b) => a.sortingId - b.sortingId),
  ];

  return (
    <NavbarStyles>
      <div className="desktop">
        <NavbarDesktop location={location} menus={menus} />
      </div>

      <div className="mobile">
        <NavbarMobile location={location} menus={menus} />
      </div>
    </NavbarStyles>
  );
}
