import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import GlobalStyles from "../../styles/GlobalStyles";
import styled from "styled-components";

import Navbar from "./navbar/Navbar";
import Footer from "./Footer";
import ScrollToTop from "../utility/ScrollToTop";
import CookieNoticeBanner from "../utility/CookieNoticeBanner";
import normalizeLinkPath from "../../utils/normalizeLinkPath";

const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--black);

  main {
    width: 100%;
    flex-grow: 1;
    margin-top: 150px;

    @media (max-width: 1101px) {
      margin-top: 137px;
    }

    @media (max-width: 950px) {
      margin-top: 135px;
    }

    @media (max-width: 680px) {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin-top: 80px;
    }

    @media (pointer: none), (pointer: coarse) {
      margin-top: 50px;
    }

    > div {
      margin-top: 4%;

      @media (max-width: 950px) {
        margin-top: 0%;
      }

      @media (pointer: none), (pointer: coarse) {
        margin-top: 0;
      }

      @media (max-width: 860px) {
        margin-top: 6%;
      }
    }
  }
`;

export default function Layout({ children, location }) {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const animTimeout = useRef(null);

  useEffect(() => {
    if (!animTimeout.current) {
      animTimeout.current = setTimeout(() => {
        setShouldAnimate(false);
      }, 1500);
    }

    return () => {
      clearTimeout(animTimeout.current);
      animTimeout.current = null;
    };
  }, [shouldAnimate]);

  useLayoutEffect(() => {
    setTimeout(() => document.body.scrollTo(0, 0));
  }, [location.pathname]);

  return (
    <LayoutStyles className={shouldAnimate ? "intro-animation" : ""}>
      <GlobalStyles />
      <Navbar
        location={{
          ...location,
          pathname: normalizeLinkPath(location.pathname),
        }}
      />
      <ScrollToTop />
      <main>{children}</main>
      <Footer />
      <CookieNoticeBanner />
    </LayoutStyles>
  );
}
