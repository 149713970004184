import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @keyframes intro1 {
    from {
      transform: scale(0.5) translateX(50px);
    }

    to {
      transform: scale(1) translateX(0px);
    }
  }

  @keyframes intro2 {
    from {
      opacity: 0;
      transform: translateY(50px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .intro-animation {
    main {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span,
      img {
        animation: intro1 1.5s;
      }

      div {
        animation: intro2 1.5s;
      }
    }
  }

  :root {
    --text-font: "Open Sans", sans-serif;
    --header-font: "Cinzel", serif;
    --quote-font: "Cormorant SC", serif;
    --the-royal-family-font: "Cinzel", serif;

    // Theme colors
    --white: #fff;
    --black: #222222;

    --light-grey: #f6f6f6;
    --medium-grey: #ededed;
    --grey: #adadad;
    --dark-grey: #494949;
    --darker-grey: #333333;
    --darkest-grey: #292929;

    --tan: #fcf5cf;
    --darker-tan: #f4e2d3;
    --gold: #f9ca7a;
    --copper: #bd7d3a;
    --green: #032d0f;

    // cookies banner color
    --cookies: #3dce91;

    // dark blue
    --color3: #002233;

    // bright blue
    --color4: #80d4ff;

    // light blue
    --color5: #80d4ff3d;

    // logo colors
    --logo-blue: #2747ad;
    --logo-yellow: #fdbb1d;
    --logo-green: #028703;
    --logo-orange: #f96624;
    --logo-light-blue: #93a3d6;
    --logo-black: #231f20;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  h1,
  h2 {
    font-family: var(--header-font);
    color: var(--copper);
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: var(--text-font);
    text-transform: uppercase;
    color: var(--copper);

  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: 0.8rem;
    color: var(--tan);
  }

  li {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  div, p, input, textarea {
    font-family: var(--text-font);
  }

  a {
    text-decoration: none;
    color: var(--tan);

    &:hover {
      color: var(--gold);
      transition: all ease-in-out 0.5s;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }

  button {
    padding: 0.7rem;
    border-radius: 3px;
    color: var(--black);

    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: var(--text-font);
    background-color: var(--tan);

    a {
      color: var(--tan);
    }

    &:hover {
      background-color: var(--gold);
      transition: all ease-in-out 0.5s;
    }
  }

  .button-dark {
    background-color: var(--black);
    color: var(--tan);
    border: 1px solid var(--tan);
    border-radius: 0;
    font-size: 1rem;

    &:hover {
      background-color: var(--black);
      color: var(--gold);
      border: 1px solid var(--gold);
    }
  }

  .bkg-color {
    background-color: var(--green);
    padding: 4rem;

    h2, h3 {
      color: var(--tan);
    }
  }

  // Forms
  input,
  textarea {
    font-size: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    resize: none;
    transition: border-bottom 0.5s ease-out;
    background-color: var(--white);


    &:focus {
      outline: none;

      border-bottom: 3px solid var(--color3);
    }
  }

  input {
    width: 100%;
    height: 1.5em;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 200px;
  }

  fieldset {
    border: none;
  }

  // print version
  @page {
    margin: 2cm;

    h2 {
      break-before: page !important;
    }
  }

  @media print {
     * {
       overflow: visible !important;
     }

    nav,
    .sidebar,
    .top-toc,
    .share,
    .bottom-content,
    footer,
    form,
    img,
    .caption,
    .arrow,
    .bg-cookie {
      display: none !important;
    }

    .blog-info {
      grid-template-columns: 1fr !important;
    }

    .published {
      margin: 0 !important;
    }

    .inline-text {
      text-align: left !important;
    }

    h1 {
      margin: 0 !important;
    }

    main {
      width: 95% !important;
      padding:  0px !important;
      margin: 0 auto !important;

      &::before {
        content: "Family I Love - familyilove.com";
      }

      &::after {
        font-weight: bold;
        content: "©️ 2024 Family I Love | familyilove.com ☦ Glory to God for All Things!"
      }

    }
  }

  img {
    box-shadow: #0b0b0b 0px 4px 15px;
  }
`;

export default GlobalStyles;
