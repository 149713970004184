import React from "react";
import styled from "styled-components";

import logo from "../../images/navbar-logo.png";

const LogoStyles = styled.div`
  .logo {
    display: block;
    margin: 1em;
    width: 300px;
    box-shadow: none;

    @media (max-width: 680px) {
      width: 100px;
      max-width: 100%;
    }

    @media (pointer: none), (pointer: coarse) {
      width: 100px;
      max-width: 100%;
    }
  }
`;

export default function Logo() {
  return (
    <LogoStyles>
      <img src={logo} alt="family i love logo" className="logo" />
    </LogoStyles>
  );
}
