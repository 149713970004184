import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UpArrowAlt } from "@styled-icons/boxicons-solid";

const ScrollStyles = styled.span`
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index: 5;
  margin: 2rem 1rem;

  .arrow {
    cursor: pointer;
    position: static;
    right: 0;
    bottom: 0;

    @media (max-width: 1000px) {
      display: none;
    }

    svg {
      color: var(--tan);
      border-radius: 50%;
      width: 4rem;
    }
  }
`;

const makeVisible = 300;

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(makeVisible ? false : true);

  const handleClick = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > makeVisible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    if (makeVisible) {
      document.body.addEventListener("scroll", handleScroll);
      return () => document.body.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <ScrollStyles>
      {isVisible && (
        <span
          className="arrow"
          aria-label="scroll to top"
          onClick={handleClick}
          onKeyPress={handleClick}
        >
          <UpArrowAlt />
        </span>
      )}
    </ScrollStyles>
  );
}
